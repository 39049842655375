.ProseMirror-menubar-wrapper {
    width: 100%;
}

.ProseMirror {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    background: #fff;
    width: 100%;
    min-height: 225px;
    padding-bottom: 25px;

    white-space: pre-wrap;
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror hr {
    padding: 2px 10px;
    border: none;
    margin: 1em 0;
}

.ProseMirror hr:after {
    content: "";
    display: block;
    height: 1px;
    background-color: silver;
    line-height: 2px;
}

.ProseMirror ul,
.ProseMirror ol {
    padding-left: 30px;
}

.ProseMirror blockquote {
    padding-left: 1em;
    border-left: 3px solid #eee;
    margin-left: 0;
    margin-right: 0;
}

.ProseMirror pre {
    padding-left: 1em;
    border-left: 3px solid #eee;
    margin-left: 0;
    margin-right: 0;
}

.ProseMirror img {
    cursor: default;
    max-width: 100%;
}

.ProseMirror th,
.ProseMirror td {
    border: 1px solid #eee;
    padding: 2px 5px;
}

.ProseMirror sup,
.ProseMirror sub {
    line-height: 0;
}

.ProseMirror span[data-shy] {
    opacity: 0.5;
}

.ProseMirror a[id]:before {
    content: "⚓ "
}

.ProseMirror a[id] {
    background: rgba(128, 128, 128, 0.3);
    padding: 2px;
    border-radius: 6px;
    margin: 0px 3px;
    font-size: 12px;
    width: 14px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    height: 14px;
    vertical-align: middle;
    line-height: 14px;
    transition: width 0.3s, background-color 0.3s;
    user-select: none;
}

.ProseMirror a[id]:hover {
    width: auto;
    background: rgba(128, 128, 128, 0.15);
}

.ProseMirror a[id].ProseMirror-selectednode {
    background: rgba(128, 192, 255, 0.3);
}

.ProseMirror table {
    margin: 15px 0;
    padding: 0;
    border-spacing: 0;
}

.ProseMirror table tr {
    border-top: 1px solid #cccccc;
    background-color: white;
    margin: 0;
    padding: 0;
}
  
.ProseMirror table tr:nth-child(2n) {
    background-color: #f8f8f8;
}
  
.ProseMirror table tr th {
    font-weight: bold;
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
}
  
.ProseMirror table tr td {
    border: 1px solid #cccccc;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
}

.ProseMirror table tr th p, .ProseMirror table tr td p {
    min-height: 1em;
}

.ProseMirror table tr p {
    margin-top: 0;
    margin-bottom: 0;
}
  
.ProseMirror table tr th :first-child, .ProseMirror table tr td :first-child {
    margin-top: 0;
}
  
.ProseMirror table tr th :last-child, .ProseMirror table tr td :last-child {
    margin-bottom: 0;
}

.ProseMirror ol ol {
    list-style: lower-alpha;
}

.ProseMirror ol ol ol {
    list-style: lower-roman;
}


.subheadline {
    text-transform: uppercase;
}